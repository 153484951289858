import React from "react";
import { List, Users, FilePlus, Activity, Clipboard, AlertTriangle } from "react-feather";
import { useNavigate } from "react-router";
import Global from "../../utils/Global";
export default function SideNavbar(props) {
  let navigate = useNavigate();

  const onStudyOverviewPressed = async (event) => {
    event.preventDefault();
    navigate("/studyOverview");
  };

  const onManageSubjectsPressed = async (event) => {
    event.preventDefault();
    navigate("/dashboard");
  };

  const onManageTeamPressed = async (event) => {
    event.preventDefault();
    navigate("/manageTeam");
  };

  const onReviewReportsPressed = async (event) => {
    event.preventDefault();
    navigate("/reviewReports");
  };

  const onRemoteAlertsPressed = async (event) => {
    event.preventDefault();
    navigate("/remoteAlerts");
  };

  return (
    <div id="layoutSidenav_nav">
      <nav className="sidenav shadow-right sidenav-light">
        <div className="sidenav-menu">
          <div className="nav accordion" id="accordionSidenav">
            {/* Sidenav Heading (Addons)*/}
            {/* <div className="sidenav-menu-heading">{"Provider Code: " + JSON.parse(String(window.localStorage.getItem("providerCode")))}</div> */}
            <div className="sidenav-menu-subheading">Main</div>
            {/* Sidenav Link (Tables)*/}
            <div className={"nav-link" + (props.activeTab.studyOverview ? " active" : "")} onClick={onStudyOverviewPressed}>
              <div className="nav-link-icon">
                <Activity size={20} />
              </div>
               Study Overview
            </div>
            <div className={"nav-link" + (props.activeTab.subjects ? " active" : "")} onClick={onManageSubjectsPressed}>
              <div className="nav-link-icon">
                <List size={20} />
              </div>
                Manage Subjects
            </div>
            {/* Manage Patients*/}
            <div className={"nav-link" + (props.activeTab.manageTeam ? " active" : "")} onClick={onManageTeamPressed}>
              <div className="nav-link-icon">
                <Users size={20} />
              </div>
              Manage Team
            </div>
            <div className={"nav-link" + (props.activeTab.reviewReports ? " active" : "")} onClick={onReviewReportsPressed}>
              <div className="nav-link-icon">
                <Clipboard size={20} />
              </div>
              Review Reports
            </div>
            <div className={"nav-link" + (props.activeTab.remoteAlerts ? " active" : "")} onClick={onRemoteAlertsPressed}>
              <div className="nav-link-icon">
                <AlertTriangle size={20} />
              </div>
              Remote Alerts
            </div>
            {/* Upload Data*/}
            {/* {props.patientSubHeaderEnabled ? (
              <>
                <div className="sidenav-menu-subheading">Patient</div>
                <a className="nav-link" href="#!" data-bs-toggle="modal" data-bs-target="#exampleModalCenter">
                  <div className="nav-link-icon">
                    <FilePlus size={20} />
                  </div>
                  Add Data File
                </a>{" "}
              </>
            ) : (
              ""
            )} */}
          </div>
        </div>
        {/* Sidenav Footer*/}
        <div className="sidenav-footer">
          <div className="sidenav-footer-content">
            <div className="sidenav-footer-subtitle">Logged in as:</div>
            <div className="sidenav-footer-title">
              {Global.localAppHandler && Global.localAppHandler.user ? Global.localAppHandler.user.name : ""}
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}
