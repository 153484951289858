import React, { useState, useEffect, useMemo } from "react";
import { useGlobalFilter, useSortBy, useTable } from "react-table";
import { PlusCircle } from "react-feather";
import { GlobalFilter } from "../GlobalFilter";
import { useNavigate } from "react-router-dom";
import Global from "../../utils/Global";

export function MetricsTable({ metrics, ...props }) {
  return (
    <>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Metric Name</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Stride Time</td>
            <td>{metrics && metrics.average_stride_time ? metrics.average_stride_time + " ms" : "1140 ms"}</td>
          </tr>
          <tr>
            <td>Swing Asymmetry</td>
            <td>{metrics && metrics.swing_asi ? metrics.swing_asi + "%" : "3.5%"}</td>
          </tr>
          <tr>
            <td>Stance Asymmetry</td>
            <td>{metrics && metrics.stance_asi ? metrics.stance_asi + "%" : "2.1%"}</td>
          </tr>
          <tr>
            <td>Swing Time (L)</td>
            <td>{metrics && metrics.left_metrics.average_swing_time ? metrics.left_metrics.average_swing_time + " ms" : "308.51 ms"}</td>
          </tr>
          <tr>
            <td>Swing Time (R)</td>
            <td>{metrics && metrics.right_metrics.average_swing_time ? metrics.right_metrics.average_swing_time + " ms" : "321.42 ms"}</td>
          </tr>
          <tr>
            <td>Stance Time (L)</td>
            <td>{metrics && metrics.left_metrics.average_stance_time ? metrics.left_metrics.average_stance_time + " ms" : "831.49 ms"}</td>
          </tr>
          <tr>
            <td>Stance Time (R)</td>
            <td>{metrics && metrics.right_metrics.average_stance_time ? metrics.right_metrics.average_stance_time + " ms" : "818.58 ms"}</td>
          </tr>
          <tr>
            <td>Step Count (L)</td>
            <td>{metrics ? metrics.left_metrics.step_count : "52"}</td>
          </tr>
          <tr>
            <td>Step Count (R)</td>
            <td>{metrics ? metrics.right_metrics.step_count : "53"}</td>
          </tr>
          {/* <tr>
            <td>Magnitude ASI</td>
            <td>{metrics ? metrics.mag_asi : "15%"}</td>
          </tr> */}
        </tbody>
      </table>
    </>
  );
}
