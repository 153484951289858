import Navbar from "../components/NavBars/Navbar";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import SideNavbar from "../components/NavBars/SideNavbar";
import Footer from "../components/Footer";
import PatientHeader from "../components/Headers/PatientHeader";
import PatientNavbar from "../components/NavBars/PatientNavbar";
import "chartjs-adapter-date-fns";
import Global from "../utils/Global";
import { ReportsByPeriodTable } from "../components/Tables/ReportsByPeriodTable";
import { TrialTable } from "../components/Tables/TrialTable";
import DropdownTable from "../components/Tables/DropdownTable";

function generateDateEntries(startDate, endDate) {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const result = [];

  const getRandomTime = () => {
    const hour = Math.floor(Math.random() * 12) + 8; // Random hour between 8 AM and 8 PM
    const minute = Math.floor(Math.random() * 60);
    const second = Math.floor(Math.random() * 60);
    return { hour, minute, second };
  };

  for (let date = new Date(start); date <= end; date.setDate(date.getDate() + 1)) {
    const { hour, minute, second } = getRandomTime();
    const entryDate = new Date(date);
    entryDate.setHours(hour, minute, second);

    const name = entryDate.toISOString();
    const dateFormatted = entryDate.toLocaleString("en-US", { hour12: true });

    result.push({ name, date: dateFormatted });
  }

  return result;
}

// Chart.register(chartDataLabels);
export default function Reports() {
  const [isLoading, setIsLoading] = useState(true);
  const [subject, setSubject] = useState(null);
  const [periodInformation, setPeriodInformation] = useState(null);

  const [selectedRowName, setSelectedRowName] = useState("");
  const [selectedRowId, setSelectedRowId] = useState("");
  const [trials, setTrials] = useState(null);

  let navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const subject = await Global.localAppHandler.app_get_subject();
        const periodInformation = await Global.localAppHandler.app_retrieve_period_information({ include_all_periods: true });
        const trials = await Global.localAppHandler.app_get_trial_list();
        // Process and set state as necessary

        // console.log("PERIOD INFORMATION");
        // console.log(periodInformation);

        // const periodInformation = [{start_date: "01/01/2020", end_date: "02/01/2020", period_length: 30, days_collected: 20},
        // {start_date: "02/01/2020", end_date: "03/01/2020", period_length: 30, days_collected: 10},
        // {start_date: "01/01/2020", end_date: "02/01/2020", period_length: 30, days_collected: 20},
        // {start_date: "01/01/2020", end_date: "02/01/2020", period_length: 30, days_collected: 20},
        // {start_date: "01/01/2020", end_date: "02/01/2020", period_length: 30, days_collected: 20},
        // {start_date: "01/01/2020", end_date: "02/01/2020", period_length: 30, days_collected: 20}
        // ]

        setSubject(subject);
        setPeriodInformation(periodInformation);
        setTrials(trials);
      } catch (error) {
        navigate("/dashboard");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [navigate]);

  useEffect(() => {
    document.body.classList.add("nav-fixed");

    return () => {
      document.body.classList.remove("nav-fixed");
    };
  }, []);

  return (
    <>
      <Navbar />
      <div id="layoutSidenav">
        <SideNavbar
          patientSubHeaderEnabled={true}
          activeTab={{ studyOverview: false, subjects: true, manageTeam: false, reviewReports: false, remoteAlerts: false }}
        />
        <div id="layoutSidenav_content">
          <main>
            <PatientHeader bg={"bg-white"} subjectName={subject ? subject.name : ""} />
            {/* Main page content*/}
            <div className="container-xl px-4 mt-n10">
              <PatientNavbar activeTab={{ overview: false, trials: false, detailed: false, gait_metrics: false, reports: true }} />

              {/* <div className="card mb-4"> */}
              {/* <div className="card-body">
                  <TrialTable
                    trials={trials ? trials : []}
                    allowTwoSelections={false}
                    setSelectedRowName={setSelectedRowName}
                    setSelectedRowId={setSelectedRowId}
                    isLoading={isLoading}
                  />
                </div> */}
              {subject && subject.name === "S0000" ? (
                <>
                  <DropdownTable
                    title={"Current Period: 07/20/2024 - 08/20/2024"}
                    data={generateDateEntries("2024-07-21", "2024-07-23")}
                    startOpen={true}
                    reportReady={false}
                    filename={""}
                  />
                  <DropdownTable
                    title={"06/20/2024 - 07/20/2024"}
                    data={generateDateEntries("2024-06-21", "2024-07-20")}
                    filename={"S0000-06_20_2024-07_20_2024.pdf"}
                  />
                  <DropdownTable
                    title={"05/20/2024 - 06/20/2024"}
                    data={generateDateEntries("2024-05-21", "2024-06-20")}
                    filename={"S0000-05_20_2024-06_20_2024.pdf"}
                  />
                  <DropdownTable
                    title={"04/20/2024 - 05/20/2024"}
                    data={generateDateEntries("2024-04-21", "2024-05-20")}
                    filename={"S0000-04_20_2024-05_20_2024.pdf"}
                  />
                </>
              ) : subject && subject.name === "S0001" ? (
                <>
                  <DropdownTable
                    title={"Current Period: 07/20/2024 - 08/20/2024"}
                    data={generateDateEntries("2024-07-21", "2024-07-23")}
                    startOpen={true}
                    reportReady={false}
                    filename={""}
                  />
                  <DropdownTable
                    title={"06/20/2024 - 07/20/2024"}
                    data={generateDateEntries("2024-06-21", "2024-07-20")}
                    filename={"S0001-06_20_2024-07_20_2024.pdf"}
                  />
                  <DropdownTable
                    title={"05/20/2024 - 06/20/2024"}
                    data={generateDateEntries("2024-05-21", "2024-06-20")}
                    filename={"S0001-05_20_2024-06_20_2024.pdf"}
                  />
                </>
              ) : (
                <>
                  <DropdownTable
                    title={"Current Period: 07/23/2024 - 08/22/2024"}
                    data={[]}
                    startOpen={true}
                    reportReady={false}
                    filename={""}
                  />
                </>
              )}

              {/* </div> */}

              {/* <ReportsByPeriodTable periods={periodInformation}/> */}
            </div>
          </main>

          <Footer light={true} />
        </div>
      </div>
    </>
  );
}
